import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

const mapState = { center: [52.13705, 23.673359], zoom: 18 };
const placeMark = {
  geometry: mapState.center,
  properties: {
    iconCaption: 'БИТЛЭНД',
    balloonContentHeader: 'Адрес организации:',
    balloonContentBody: 'Республика Беларусь 224025, г. Брест, ул. Лейтенанта Рябцева, 108 И',
    balloonContentFooter: 'Тел: +375(29)620-98-02'
  },
  modules:
    ['geoObject.addon.balloon', 'geoObject.addon.hint']

}


const Ymap = () => {
  return (
    <YMaps>
      <div>
        <Map defaultState={mapState}>
          <Placemark {...placeMark} />
        </Map>
      </div>
    </YMaps>
  );
};

export default Ymap;
