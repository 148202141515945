import React from "react";
import Separator from "../Separator";
import styles from "./style.module.css";

const Banner = () => {
  return (
    <section className={styles.dirWrapper}>
      <div className={styles.textWrapper}>
        <h1>КОНТАКТНЫЕ ДАННЫЕ:</h1>
        <span className={styles.text}>Управляющая компания:</span>
        <span className={styles.text}>ЗАО "СВтраст"</span>
        <Separator />
        <span className={styles.text}>Директор: Межень Валерий Иосифович</span>
        <div className={styles.telWrapper}>
          <span className={styles.operator}>A1: </span>
          <a className={styles.tel} href="tel:+375296209802">
            +375 29 620-98-02
          </a>
        </div>
      </div>
    </section>
  );
};

export default Banner;
