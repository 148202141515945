import React from 'react';
import DefaultLayout from './components/DefaultLayout';
import './App.scss';
import './fontFace.css';
import './variables.css';

function App() {
  return (
    <div className="App">
      <DefaultLayout>
        <div />
      </DefaultLayout>
    </div>
  );
}

export default App;
