import React, { useState } from 'react';
import styles from './style.module.css';
import images from '../ImageSource';
import CtaButton from '../CtaButton'
import useProgressiveImage from '../LazyBackground';
import EmailPopup from '../EmailPopup';
import Separator from '../Separator';
import Contacts from './Contacts'

const image = (index) => {
    return images.main.front[index];
};

const placeholder = (index) => {
    return images.placeholders.front[index];
};

const Banner = (props) => {
    const [isPopup, showPopup] = useState(false);
    const index = window.location.pathname === '/' ? 8 : 0;
    const source = image(index);
    const sourcePlaceholder = placeholder(index)
    const loaded = useProgressiveImage(source)
    let button;
    if (props.button) {
        button = <CtaButton text='Оставить Заявку' click={() => showPopup(!isPopup)} />
    }

    const mainText = () => {
        if (props.mainText) {
            return (<>
                <span className={`${styles.mainText} ${styles.mainPageText}`}>Аренда недвижимости</span>
                <Separator />
                <span className={`${styles.underlineText} ${styles.mainPageText}`}>ул. Лейтенанта Рябцева, 108И</span>
            </>
            )
        }
        if (props.contacts) {
            return <Contacts styles={styles} />
        }

    }

    return (<>
        <div className={styles.mainWrapper} style={{ backgroundImage: `url(${loaded || sourcePlaceholder})` }}>
            <div className={styles.bgFilter}>
            </div>
            <EmailPopup show={isPopup} click={() => showPopup(false)} img={sourcePlaceholder} />
            {mainText()}
            {button}
        </div>
    </>
    )
}

export default Banner;