import React from 'react';
import styles from "./style.module.css";
import MainBaner from '../../components/MainBanner'

const Offer = () => {
    return (
        <div className={styles.template}>
            <MainBaner />
        </div>
    )
}

export default Offer;