import React from 'react';
import styles from './style.module.css';
import logoBit from '../../static/images/logoBit_web_small.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <NavLink to='/' className={styles.navLink}>
    <div className={styles.logo}>
        <div className={styles.imageBlock}>
            <img className={styles.logoImage} src={logoBit} alt="logo"/>
        </div>
        <div className={styles.textBlock}>
            <div className={styles.logoMainText}>Битлэнд</div>
            <div className={styles.logoSecondText}>Аренда недвижимости</div>
        </div>
        
    </div></NavLink>)
}

export default Header;