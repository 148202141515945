import React from 'react';
import styles from './style.module.css';
import MainBaner from '../../components/MainBanner';
import Director from '../../components/Director'

const Contacts = () => {


    return (
        <div className={styles.template}>
            <MainBaner contacts={true} />
            <Director/>
        </div>
    )
}

export default Contacts;