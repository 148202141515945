import React from "react";
import styles from "./style.module.css";

const Separator = () => {
  return (
    <div className={styles.SeparatorWrapper}>
      <div className={styles.Separator}></div>
    </div>
  );
};

export default Separator;
