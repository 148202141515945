import React from 'react';
import styles from "./style.module.css";
import MainBaner from '../../components/MainBanner';
// import Promo from '../../components/Promo';
import BannerRealEstate from '../../components/BannerRealEstate'
import BannerManufacture from '../../components/BannerManufacture'
import BannerTruck from '../../components/BannerTruck'

const Home = () => {
    return (
        <div className={styles.template}>
            <MainBaner button={true} mainText={true} />
            {/* <Promo/> */}
            <BannerRealEstate />
            <BannerManufacture />
            <BannerTruck />
        </div>
    )
}

export default Home;