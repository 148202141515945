import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import Router from "../../MyRouter";
import { BrowserRouter } from "react-router-dom";

const Layout = () => {
    return (<BrowserRouter>
        <Header/>
        <Router/>
        <Footer/>
    </BrowserRouter>)
}


export default Layout;

