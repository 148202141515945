import React from 'react';
import styles from './style.module.css';
import Map from '../Ymap';
import Logo from '../Logo';

const Footer = () => {
    return (<div className={styles.footer}>
        <div className={styles.textWrapper}>
            <div>ЗАО "Битлэнд"</div>
            <div>УНП 291541890</div>
            <div>© 2020 все права защищены</div>
        </div>
        <div className={styles.logo}>
            <Logo />
        </div>
        <div className={styles.map}>
            <Map />
        </div>

    </div>)
}

export default Footer;