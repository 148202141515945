import front from '../../static/images/real-estate/front.jpg';
import front1 from '../../static/images/real-estate/front1.jpg';
import front2 from '../../static/images/real-estate/front2.jpg';
import front3 from '../../static/images/real-estate/front3.jpg';
import front4 from '../../static/images/real-estate/front4.jpg';
import front5 from '../../static/images/real-estate/front5.jpg';
import front6 from '../../static/images/real-estate/front6.jpg';
import front7 from '../../static/images/real-estate/front7.jpg';
import front8 from '../../static/images/real-estate/front8.jpg';
import inside1 from '../../static/images/real-estate/inside1.jpg';
import inside2 from '../../static/images/real-estate/inside2.jpg';
import inside3 from '../../static/images/real-estate/inside3.jpg';
import inside4 from '../../static/images/real-estate/inside4.jpg';
import inside5 from '../../static/images/real-estate/inside5.jpg';
import inside6 from '../../static/images/real-estate/inside6.jpg';
import inside7 from '../../static/images/real-estate/inside7.jpg';
import inside8 from '../../static/images/real-estate/inside8.jpg';
import p_front from '../../static/images/real-estate/placeholders/front.jpg';
import p_front1 from '../../static/images/real-estate/placeholders/front1.jpg';
import p_front2 from '../../static/images/real-estate/placeholders/front2.jpg';
import p_front3 from '../../static/images/real-estate/placeholders/front3.jpg';
import p_front4 from '../../static/images/real-estate/placeholders/front4.jpg';
import p_front5 from '../../static/images/real-estate/placeholders/front5.jpg';
import p_front6 from '../../static/images/real-estate/placeholders/front6.jpg';
import p_front7 from '../../static/images/real-estate/placeholders/front7.jpg';
import p_front8 from '../../static/images/real-estate/placeholders/front8.jpg';
import p_inside1 from '../../static/images/real-estate/placeholders/inside1.jpg';
import p_inside2 from '../../static/images/real-estate/placeholders/inside2.jpg';
import p_inside3 from '../../static/images/real-estate/placeholders/inside3.jpg';
import p_inside4 from '../../static/images/real-estate/placeholders/inside4.jpg';
import p_inside5 from '../../static/images/real-estate/placeholders/inside5.jpg';
import p_inside6 from '../../static/images/real-estate/placeholders/inside6.jpg';
import p_inside7 from '../../static/images/real-estate/placeholders/inside7.jpg';
import p_inside8 from '../../static/images/real-estate/placeholders/inside8.jpg';
import parking1 from '../../static/images/gruz/parking.jpg'
import parking2 from '../../static/images/gruz/parking2.jpg'
import sklad1 from '../../static/images/gruz/pr-sklad.jpg'
import sklad2 from '../../static/images/gruz/pr-sklad2.jpg'


const images = {
    main: {
        front: [front, front1, front2, front3, front4, front5, front6, front7, front8],
        inside: [inside1, inside2, inside3, inside4, inside5, inside6, inside7, inside8]
    },
    placeholders: {
        front: [p_front, p_front1, p_front2, p_front3, p_front4, p_front5, p_front6, p_front7, p_front8],
        inside: [p_inside1, p_inside2, p_inside3, p_inside4, p_inside5, p_inside6, p_inside7, p_inside8]
    },
    banner: {
        parking: [parking1, parking2],
        sklad: [sklad1, sklad2]
    }
}




export default images

