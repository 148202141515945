import React from "react";
import images from '../ImageSource';
import Separator from '../Separator';
import styles from "./style.module.css";

const Banner = () => {
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.imgs}>
        <div className={styles.imgWrapper}>
          <img className={styles.mainImg} src={images.banner.sklad[0]} alt="sklad1" />
        </div>

        <div className={styles.imgWrapper}>
          <img className={styles.mainImg} src={images.banner.sklad[1]} alt="sklad2" />
        </div>
      </div>

      <div className={styles.content}>
        <div className={`${styles.text} ${styles.headingText}`}>Производственно-складское помещение</div>
        <Separator />
        <div className={styles.text}>Капитальное строение позволяющее разместить продукцию</div>
        <div className={styles.text}>либо организовать производтвенный процесс</div>
      </div>
    </div >
  );
};

export default Banner;
