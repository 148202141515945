import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import CtaButton from "../CtaButton";
import useOutsideClick from "./useOutsideClick";
import emailjs, { init } from "emailjs-com";
import Spinner from "../Spinner";

(function () {
  // console.log("INIT");
  // https://dashboard.emailjs.com/admin/integration
  init("user_glqJqfXGS7fiHSxtqq2pe");
})();

const Popup = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [msgStatus, setStatus] = useState('');

  const ref = useRef();

  var templateParams = {
    to_name: "Manager",
    from_name: name,
    from_tel: tel,
    from_email: email,
    message: message,
  };

  const sendForm = () => {
    // console.log(templateParams);
    setStatus('');
    setSpinner(true);
    emailjs.send("service_eoqfgze", "template_u9yw7a9", templateParams).then(
      function () {
        setSpinner(false);
        setStatus('success');
        setName('');
        setEmail('');
        setTel('');
        setMessage('');
        setTimeout(() => {
          if (props.show) {
            props.click();
          }
          setStatus('');
        }, 1000);
        // console.log("SUCCESS!");
      },
      function (error) {
        setSpinner(false);
        setStatus('fail');
        // console.log("FAILED...", error);
      }
    );
  };

  useOutsideClick(ref, () => {
    if (props.show && !showSpinner) {
      props.click();
    }
  });

  if (props.show) {
    return (
      <>
        {showSpinner && <Spinner />}
        <div className={styles.popupBackground}>
          <div className={styles.popupWrapper}>
            <div
              className={styles.sidePopup}
              style={{ backgroundImage: `url(${props.img})` }}
            ></div>
            <form className={styles.popup} ref={ref}>
              <div className={styles.sideWrapper}>
                <span className={styles.heading}>Заявка</span>
              </div>
              <span className={styles.inputDesc}>Имя:</span>
              <input
                className={styles.input}
                type="email"
                onChange={(event) => setName(event.target.value)}
              />
              <span className={styles.inputDesc}>Email:</span>
              <input
                className={styles.input}
                type="email"
                onChange={(event) => setEmail(event.target.value)}
              />
              <span className={styles.inputDesc}>Tel:</span>
              <input
                className={styles.input}
                type="tel"
                onChange={(event) => setTel(event.target.value)}
              />
              <span className={styles.inputDesc}>Сообщение:</span>
              <textarea
                className={styles.input}
                onChange={(event) => setMessage(event.target.value)}
              />
              <div className={styles.sideWrapper}>
                <CtaButton
                  text="Отправить"
                  click={() => {
                    name && email && tel && message && sendForm();
                  }}
                />
              </div>
              {msgStatus === 'success' && <div className={styles.successMsg}>Success!</div>}
              {msgStatus === 'fail' && <div className={styles.failMsg}>FAIL!</div>}
            </form>


            <div className={styles.errorMsg}></div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Popup;
