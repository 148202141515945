import React from 'react';
import styles from './style.module.css';



const Button = (props) => {
    return (
        <div className={styles.button6} onClick={props.click}>
            <span>{props.text}</span>
        </div>
    )
}

export default Button;