import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "./pages/Error page";
import Contacts from "./pages/Contacts";
import Offer from "./pages/Offer";
import Home from "./pages/Home";

export default class Router extends Component {
    render() {
        return (
            <div className="page">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/offer" component={Offer} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route component={ErrorPage} />
                </Switch>
            </div>
        );
    }
}