import React from 'react';
import styles from './style.module.css';
import Logo from '../Logo';
import { NavLink } from 'react-router-dom';

const nav = [
    // {
    //     text: 'Предложения',
    //     link: '/offer'
    // },
    {
        text: 'Контакты',
        link: '/contacts'
    }
]

const Header = () => {
    return (<div className={styles.headerWrapper}>
        <div className={styles.upLine}><a className={`${styles.tel} ${styles.telMob}`} href="tel:+375296209802"> +375 29 620-98-02 </ a></div>
        <div className={styles.header}>
        <Logo />
        <div></div>
        <nav className={styles.navigation}>
        <a className={`${styles.tel} ${styles.telDesc}`} href="tel:+375296209802"> +375 29 620-98-02 </ a>
          <div className={styles.navLinkWrapper}><NavLink to='/contacts' className={styles.navLink} activeStyle={{
                fontWeight: "bold",
                color: "#1FB0E5"
            }}>Контакты</NavLink></div>
        </nav>
    </div>
    </div>)
}

export default Header;