import React from 'react'

const Contacts = (props) => {
    const { styles } = props
    return (
        <div className={styles.table}>
            <div className={styles.row}>
                <div className={styles.key}>Наименование:</div>
                <div className={styles.value}>Закрытое акционерное общество «Битлэнд»</div>
            </div>
            <div className={styles.row}>
                <div className={styles.key}>Дата регистрации:</div>
                <div className={styles.value}>22.02.2019г.</div>
            </div>
            <div className={styles.row}>
                <div className={styles.key}>Свидетельство о регистрации (УНП):</div>
                <div className={styles.value}>291541890</div>
            </div>
            <div className={styles.row}>
                <div className={styles.key}>Юридический адрес(почтовый):</div>
                <div className={styles.value}>Республика Беларусь 224025, г. Брест, ул. Лейтенанта Рябцева, 108 И</div>
            </div>
            <div></div>
        </div>
    )
}

export default Contacts;