import React from "react";
import styles from "./style.module.css";
import images from '../ImageSource';
import Separator from '../Separator'

const Banner = () => {
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.content}>
        <div className={styles.text}>Парковка для транспорта</div>
        <Separator />
        <div className={styles.text}>Возможно размещение грузового или строительного странспорта</div>
      </div>
      <div className={styles.imgs}>
        <div className={styles.imgWrapper}>
          <img className={styles.mainImg} src={images.banner.parking[0]} alt="inside1" />
          <img className={styles.secondImg} src={images.banner.parking[1]} alt="inside2" />
        </div>
      </div>
    </div >
  );
};

export default Banner;
