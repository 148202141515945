import React from "react";
import styles from "./style.module.css";

const Spinner = () => {
  return (
    <>
      <div className={styles.loadingiospinnerblocksgcw2k9zba2c}>
        <div className={styles.ldio9k010dc4jlh}>
          <div className={styles.random1}></div>
          <div className={styles.random2}></div>
          <div className={styles.random3}></div>
          <div className={styles.random4}></div>
          <div className={styles.random5}></div>
          <div className={styles.random6}></div>
          <div className={styles.random7}></div>
          <div className={styles.random8}></div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
